.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  padding-top: 10rem;

  h1 {
    color: white;
    font-family: $secondary-font;
    font-size: $header-font-size + 1rem;
    text-align: left;
  }


  &__bio {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    background-color: $gray-color-light;
    border-radius: 3px;
    box-shadow: 0 1rem 1rem rgba(black, 0.8);
    margin-bottom: 10rem;
    border: solid 2px $secondary-color;
    padding: 3rem 0;
  }

  p {
    text-align: right;
    hyphens: none;
    margin: 1rem 2rem;
    text-indent: 2rem;
    font-size: $default-font-size;
  }

  @include respond(tab-port) {
    &__bio {
      width: 70%;
      background-color: $gray-color-light;
      border-radius: 3px;
      box-shadow: 0 1rem 1rem rgba(black, 0.8);
      margin-bottom: 10rem;
      border: solid 2px $secondary-color;
    }

    p {
      text-align: center;
    }
  }

  @include respond(desktop) {
    &__bio {
      width: 80%;
      padding: 5rem;
      background-color: $gray-color-light;
      border-radius: 3px;
      box-shadow: 0 1rem 1rem rgba(black, 0.8);
      margin-bottom: 10rem;
      border: solid 2px $secondary-color;
    }
  }
}

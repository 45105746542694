.contact {
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: white;
    font-family: $secondary-font;
    font-size: 4rem;
    text-align: left;
  }

  &__form {
    margin: 3rem 0;
    // border: solid 1px $color-primary;
    display: flex;
    text-align: left;
    width: 80%;
    flex-direction: column;
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: $gray-color-light;
    // border: none;
    // border-bottom: 3px solid transparent;
    transition: all 0.3s;
    padding: 5rem 2rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);

    & > input,
    textarea {
      margin-bottom: 2rem;
      padding: 0.5rem;
      border: solid 1px $primary-color;
      border-radius: 2px;
    }
    & > label {
      margin-bottom: 0.5rem;
      margin-left: 1rem;
    }

    & textarea {
      height: 10rem;
      font-family: inherit;
      resize: none;
    }

    & > button {
      cursor: pointer;
      margin: 3rem 0;
      align-self: center;
      padding: 1rem;
      color: $primary-color;
      font-size: 1.6rem;
      border: none;
      border-radius: 3px;
      transition: all 0.4s;
      background-color: transparent;
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.6);

      }
    }
  }

  @include respond(tab-port) {
    &__form {
      width: 60%;
    }
  }

  @include respond(desktop) {
    &__form {
        width: 40%;
        padding: 2rem;
      }
  }
}

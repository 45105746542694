@import "../variables";

.navigation {
  height: 5rem;
  width: 5rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  background-color: $primary-color;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
  z-index: 1000;

  .button-background {
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    position: fixed;
    top: 1rem;
    right: 1rem;

    .button {
      position: fixed;
      top: 3.5rem;
      right: 2rem;
      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: white;
        display: inline-block;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      &::before {
        top: -0.8rem;
      }
      &::after {
        top: 0.8rem;
      }

      &-close {
        &::before {
          width: 2.5rem;
          top: 0;
          transform: rotate(135deg);
        }
        &::after {
          width: 2.5rem;
          top: 0;
          transform: rotate(-135deg);
        }
      }

      &-close.button {
        background-color: transparent;
      }
    }
  }

  &-open {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 0%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .menu {
    visibility: hidden;
    height: 0;
    width: 0;

    &-open {
      visibility: visible;
      height: 100%;
      width: 100%;
      .nav-list {
        list-style: none;
        color: white;
        position: absolute;
        top: 30%;
        right: 1rem;
      }

      .nav-link {
        color: white;
        background-color: transparent;
        text-decoration: none;
        text-align: right;
        transition: all 2s 0.2s;

        li {
          text-transform: uppercase;
          margin-bottom: 2rem;
          font-size: 1.6rem;
          letter-spacing: 0.4rem;
          text-align: right;
          transition: all 0.4s;
          color: white;
        }
      }

      .nav-link--active > li {
        width: 13rem;
        display: block;
        background-color: $secondary-color;
        color: $primary-color;
        padding: 0.5rem;
        border-radius: 3px;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);
        border: solid $primary-color 2px;
      }

      .nav-quote {
        position: absolute;
        bottom: 5rem;
        left: 5rem;
        width: 75%;
        -webkit-animation: fadein 5s;
        animation: fadein 5s;
        font-family: $secondary-font;
        color: $secondary-color;
        font-size: $default-font-size + 1rem;

        @-webkit-keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }

      &__footer {
        position: absolute;
        bottom: 9rem;
        right: 3rem;
        a {
          color: white;
          font-size: 3rem;
          margin-right: 2rem;
        }
      }

      &__heading {
        position: absolute;
        text-align: left;
        top: 2rem;
        left: 2rem;
        text-decoration: none;
        color: white;
        font-family: $secondary-font;
        font-size: 4rem;
      }
    }
  }

  @include respond(tab-port) {
    top: 3rem;
    right: 3rem;

    .button-background {
      top: 3rem;
      right: 3rem;

      .button {
        top: 5.5rem;
        right: 4rem;
      }
    }

    &-open {
      top: 0;
      right: 0;
    }

    .menu-open {
      .nav-list {
        right: 6rem;
      }

      &__heading {
        top: 4rem;
        left: 4rem;
        font-size: 2.5rem;
      }
    }
  }

  @include respond(desktop) {
    height: 4rem;
    width: 4rem;
    top: 5rem;
    right: 5rem;

    .button-background {
      cursor: pointer;
      height: 4rem;
      width: 4rem;
      top: 5rem;
      right: 5rem;

      .button {
        top: 7rem;
        right: 6rem;
        &,
        &::before,
        &::after {
          width: 2rem;
          height: 2px;
          background-color: white;
          display: inline-block;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }

        &::before {
          top: -0.6rem;
        }
        &::after {
          top: 0.6rem;
        }

        &-close {
          &::before {
            width: 2.5rem;
            top: 0;
            transform: rotate(135deg);
          }
          &::after {
            width: 2.5rem;
            top: 0;
            transform: rotate(-135deg);
          }
        }

        &-close.button {
          background-color: transparent;
        }
      }
    }

    &-open {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      right: 0;
      border-radius: 0%;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    .menu {
      visibility: hidden;
      height: 0;
      width: 0;

      &-open {
        visibility: visible;
        height: 100%;
        width: 100%;
        .nav-list {
          list-style: none;
          color: white;
          position: absolute;
          top: 30%;
          right: 1rem;
        }

        .nav-link {
          color: white;
          background-color: transparent;
          text-decoration: none;
          text-align: right;
          transform: translate(-50%, -50%);
          transition: all 2s 0.2s;

          li {
            text-transform: uppercase;
            margin-bottom: 2rem;
            font-size: 1.6rem;
            letter-spacing: 0.4rem;
            text-align: right;
            transition: transform 0.4s;

            &:hover {
              transform: scale(1.2) translateX(-1.5rem);
              text-shadow: 0.5rem 1rem 2rem rgba(black, 1);
              color: $secondary-color;
            }
          }
        }

        .nav-link--active > li {
          width: 18rem;
          display: block;
          background-image: linear-gradient(125deg, $primary-color white 10%);
          transform: scale(1.2) translate(-2rem);
          color: $primary-color;
          padding: 0.5rem;
          border-radius: 3px;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);

          &:hover {
            transform: scale(1.2) translate(-2rem);
            text-shadow: 0rem 0rem 0rem rgba(black, 0);
            color: $primary-color;
          }
        }

        &__footer {
          position: absolute;
          bottom: 9rem;
          right: 3rem;
          a {
            color: white;
            font-size: 3rem;
            margin-right: 2rem;
          }
        }

        &__heading {
          position: absolute;
          text-align: left;
          top: 2rem;
          left: 2rem;
          text-decoration: none;
          color: white;
          font-size: 5rem;
        }
      }
    }
  }
}

.project {
  height: 48rem;
  width: 90%;
  background-color: $gray-color-light;
  border-radius: 5px;
  box-shadow: 0 1rem 1rem rgba(black, 0.8);
  margin-bottom: 5rem;
  border: solid $secondary-color 2px;

  &__title {
    padding: 1rem;
    border-bottom: solid $secondary-color 2px;

    h3 {
      font-size: 2rem;
      //   font-family: $secondary-font;
      //   text-transform: uppercase;
    }
  }

  &__preview {
    img {
      width: 100%;
      height: 20rem;
      object-fit: contain;
      margin: 1rem 0;
    }
  }
  &__links {
    font-size: 1.6rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a {
      border: solid black 1px;
      border-radius: 5px;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      box-shadow: 0 0.3rem 0.5rem rgba(black, 0.6);
      transition: all 0.3s;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0.8rem 1.2rem rgba(black, 0.6);
        background-color: $primary-color;
        border: solid $secondary-color 1px;
        color: $secondary-color;
      }
    }
  }

  &__description {
    padding: 3rem;
    hyphens: none;
    text-indent: 2rem;
    font-size: 125%;
  }

  @include respond(tab-port) {
    width: 60%;
  }

  @include respond(desktop) {
    width: 35%;
    transition: all 0.3s;
  }
}

.work {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  background-color: $primary-color;
  overflow: hidden;
  align-items: center;

  &__title {
    margin: 5rem;
    color: white;
    font-family: $secondary-font;
    text-transform: uppercase;
    font-size: $header-font-size;
  } 
  &__projects {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__link {
    color: white;
    font-family: $secondary-font;
    text-decoration: none;
    font-size: $header-font-size;
    transition: all .3s;

    &:hover {
      color: $secondary-color;
      text-shadow: 0 1rem 1rem rgba(black, .7);
      transform: scale(1.1);

    }
  }
  @include respond(tab-port) {
      text-align: left;

      .work__projects {
      }
  }
}

@import "../sass/mixins";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 70%;
  font-family: $primary-font;
  hyphens: auto;


  .logo {
    font-size: 4rem;
    color: white;
    font-family: $secondary-font;
    position: absolute;
    top: 2rem;
    left: 2rem;
    text-decoration: none;
  }

  @include respond(tab-port) {
    font-size: 85%;
  }

  @include respond(desktop) {
    font-size: 95%;
    padding: 2rem;
    background-color: $gray-color-dark;

    body {
      border: solid $primary-color 2px;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.7);
      border-radius: 3px;
    }

    .logo {
      top: 4rem;
      left: 4rem;
    }
  }

  .loader-container {
    background-color: $primary-color;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 999;
  }
  
  .loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 5px solid $secondary-color;
    animation: load 1.1s infinite linear;
    transition: opacity 0.3s;
  }

  .loader--hide {
    display: none;
  }
  
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media screen and (max-width: 47em) {
        @content;
      }
    }
    @if $breakpoint == tab-port {
      @media screen and (min-width: 47em) {
        @content;
      }
    }
    @if $breakpoint == tab-land {
      @media screen and (min-width: 75em) {
        @content;
      }
    }
    @if $breakpoint == desktop {
      @media screen and (min-width: 80em) {
        @content;
      }
    }
  }
  
.home {
  height: 100vh;
  width: 100%;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__hero {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(black, 0.9) 5%,
        rgba(transparent, 0) 20%,
        rgba(black, 0.4)
      ),
      url("../../assets/bg.jpg");
    background-size: cover;
    background-position: center;
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    &__title {
      font-family: $secondary-font;
      margin: 1rem 2rem;
      margin-right: 10rem;
      color: white;
      text-shadow: 0.5rem 0.5rem 1rem rgba(black, 1);
      font-size: $header-font-size + 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }
    &__banner {
      display: inline-flex;
      flex-direction: column;
      text-align: left;
      font-size: 150%;
      color: white;
      padding: 3rem 3rem;
      hyphens: none;
      // background-color: rgba(white, .3);
      // box-shadow: 0 .5rem 1rem rgba(black, .7);

      h2 {
        // color: $primary-color;
        text-transform: uppercase;
      }

      hr {
        background-color: white;
        height: 0.1rem;
        border: none;
        
      }

      p {
        font-style: italic;
      }

      & > * {
        margin: 1rem;
      }

      &__scroll {
        visibility: visible;
        align-self: center;
        font-size: 300%;
        color: white;
        text-align: center;
        font-family: $secondary-font;
        -webkit-animation: bounce 5s infinite;
        animation: bounce 5s infinite;
        padding: 1rem;
      }
    }

    &__footer {
      // visibility: hidden;
      display: inline-flex;
      visibility: visible;
      font-size: 300%;
      align-self: flex-end;
      margin: 1rem;
      a {
        color: white;
      }

      & > * {
        margin: 0.5rem;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.2) translateY(-0.5rem);
          cursor: pointer;
          color: $primary-color;
        }
      }
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(0rem);
    }
    50% {
      transform: translateY(4rem);
      color: $secondary-color;
    }
    100% {
      transform: translateY(0rem);
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0rem);
    }
    50% {
      transform: translateY(4rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }

  @include respond(tab-port) {
    &__hero {
      height: 100vh;

      background-size: cover;
      background-position: center;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      display: flex;
      flex-direction: column;
      align-content: center;
      &__title {
        margin: 3rem;
      }
      &__banner {
        &__scroll {
          visibility: hidden;
        }
      }
      &__footer {
        display: inline-flex;
        visibility: visible;
        font-size: 300%;
        align-self: flex-end;
        margin: 1rem;
        a {
          color: white;
        }

        & > * {
          margin: 0.5rem;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.2) translateY(-0.5rem);
            cursor: pointer;
            color: $primary-color;
          }
        }
      }
    }
  }

  @include respond(desktop) {
    &__hero__banner {
      p {
        margin-left: 5rem;
      }
      hr {
        width: 50%;
        margin-left: 3rem;
      }
    }
  }
}

.footer {
  background-color: $primary-color;
  border-top: solid $secondary-color 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  a {
    font-size: 2.5rem;
    color: white;
    text-decoration: none;
    margin: 0 0.5rem;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
      color: $secondary-color;
    }
  }
  h2 {
    text-align: right;
    font-family: $secondary-font;
    color: white;
    margin-right: 2rem;
    font-size: 2rem;
  }

  @include respond(tab-port) {
    
    a {
      font-size: 3rem;
      margin: 0 1rem;
    }
    h2 {
      font-size: 3rem;
    }
  }
}
